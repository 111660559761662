let resumeData = {
  logotype: 'images/Logo_jerquilo.png',
  name: '.{Jerson Quintero}',
  role: 'Full Stack Engineer',
  email: 'dev@jersonquintero.com',
  phoneNumber: '701 713 5688',
  location: 'Seattle WA',
  linkedinId: 'jersonquinterolopez',
  roleDescription:
    'Full-stack Engineer | ODX1 fellow | MongoDB | Express | React | Nodejs | MySQL | TypeScript | Javascript | Firebase | GraphQL | UI/UX designer | Product design | #Remote',
  socialLinks: [
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jersonquinterolopez/',
      className: 'fab fa-linkedin',
    },
    {
      name: 'github',
      url: 'https://github.com/Jersonquinterolopez',
      className: 'fab fa-github',
    },
    {
      name: 'Behance',
      url: 'https://www.behance.net/jerquilo',
      className: 'fab fa-behance',
    },
    {
      name: 'skype',
      url: 'https://twitter.com/jerquilo',
      className: 'fab fa-twitter',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/JersonQuinteroLopez/',
      className: 'fab fa-facebook-f',
    },
  ],
  aboutme:
    'Full Stack Engineer with four years of experience working as a product engineer and lead engineer in fast-moving environments. Extensive experience working with Javascript ES6+, React, HTML5, Typescript, MongoDB, and Express. Strong understanding of design principles, UI/UX (CSS Modules, Sass, Chakra-UI, Bulma, Material-UI), and responsive design. I co-founded two startups where I played a key role in software architecture and development to ensure maximum accessibility, user experience, and usability. I am a quick learner who can absorb new ideas and communicate clearly and effectively. I spend my free time researching how technology can solve complex social problems involving climate change, economic inequality, education, open banking, and healthcare.',
  address: 'dev@jersonquintero.com',
  website: '+1 (701) 713 5688',
  education: [
    {
      UniversityName: 'OnDeck ODX',
      specialization: 'ODX1 Fellow - Business development',
      MonthOfPassing: '',
      YearOfPassing: '2021',
      Achievements: '',
    },
    {
      UniversityName: 'YC Startup School',
      specialization: 'Business development & startup ecosystem',
      MonthOfPassing: '',
      YearOfPassing: '2021',
      Achievements: '',
    },
    {
      UniversityName: 'Platzi',
      specialization: 'Computer Science',
      MonthOfPassing: '',
      YearOfPassing: '2016 - 2020',
      Achievements: '',
    },
    {
      UniversityName: 'International House Bogota',
      specialization: 'Foreign language',
      MonthOfPassing: '',
      YearOfPassing: '2014 - 2016',
      Achievements: 'English A1/ A2',
    },
    {
      UniversityName: 'English Smart Academy',
      specialization: 'Foreign language',
      MonthOfPassing: '',
      YearOfPassing: '2014 - 2016',
      Achievements: 'English B1 - B2 Level',
    },
  ],
  work: [
    {
      CompanyName: 'Figuro',
      specialization: 'Lead Engineer',
      MonthOfLeaving: '',
      YearOfLeaving: '2021 - 2022',
      Achievements: [
        {
          id: 1,
          bullet:
            '• Since I assumed my role in 2021, I have executed critical decisions in the architecture and product design that resulted in the delivery of more than 20 versions of our technology and a diverse set of RestAPIs to optimize the sales process in our back-office.',
        },
        {
          id: 2,
          bullet:
            '• Simultaneously, I spearheaded a roadmap for the company`s vision by packaging our latest and emerging features into strategic releases. Thanks to my execution of the product roadmap, we are now part of the high-impact accelerator programs in LATAM including Startup Chile, Latitude, and Utec ventures, as well as in Silicon Valley including OnDeck ODX.',
        },
        {
          id: 3,
          bullet:
            '• Under my leadership, we multiplied our GMV by a factor of ten in the last two quarters. Additionally, our net income has grown at an average pace of 40% each month and has further increased to 48% in the first quarter of 2022.',
        },
      ],
    },
    {
      CompanyName: 'Figuro',
      specialization: 'Full-stack software Engineer',
      MonthOfLeaving: '',
      YearOfLeaving: '2021',
      Achievements: [
        {
          id: 1,
          bullet:
            '• Efficiently implemented a variety of full-stack features, including RESTful APIs with Nodejs, Express, MongoDB, and MySQL to obtain instant quotes in our React client application.',
        },
        {
          id: 2,
          bullet:
            '• Used React and Firebase to provide instant quotes with multistep forms in different insurance categories.',
        },
        {
          id: 3,
          bullet:
            '• Leading in designing and managing the client and server architecture using microservices.',
        },
        {
          id: 4,
          bullet:
            '• Implemented CI/CD pipelines using GitHub Actions and Firebase preview channels.',
        },
        {
          id: 5,
          bullet:
            '• Responsible for the migration of our quote APIs from Heroku to Vercel.',
        },
        {
          id: 6,
          bullet:
            '• Created React global state using Context API with a FLUX architecture.',
        },
        {
          id: 7,
          bullet:
            '• Involved in creating our user dashboard using React, Bulma CSS, Chakra-UI, and Styled Components.',
        },
        {
          id: 8,
          bullet:
            '• Designed and Implemented software as a service for insurance agents to optimize the policy underwriting process.',
        },
      ],
    },
    {
      CompanyName: 'Wellbe technologies',
      specialization: 'Front-end Engineer - Founder',
      MonthOfLeaving: '',
      YearOfLeaving: '2018 - 2020',
      Achievements: [
        {
          id: 1,
          bullet:
            '• I co-funded Wellbe, an easy-to-use technology to improve the way that people connect with their health system. To do so, I performed two MVPs in one year. Wellbe acted as a lab where I researched and discovered new technologies to solve bureaucracy problems in the healthcare system and improve the users’ lives.',
        },
        {
          id: 2,
          bullet:
            '• Stack: React, NodeJs, Express, MongoDB, combining the best of the top frameworks and libraries in the industry, working most of the time on the client-side. I used consuming APIS like Google maps, Here maps ( geolocation - geocoding ), Watson Assistance (server-side), integrating Facebook PIXELS. Besides.',
        },
      ],
    },
    {
      CompanyName: 'Pgp - contract',
      specialization: 'Front-end Engineer - IT consultant ',
      MonthOfLeaving: '',
      YearOfLeaving: '2019',
      Achievements: [
        {
          id: 1,
          bullet:
            '• This e-commerce platform, experienced a low conversion rate, detected by the Facebook pixel. The problem was that people who visited the site would leave within 10 or 15 seconds. The reasons relied on the payment gateways with a complicated UI and a bad UX.',
        },
        {
          id: 2,
          bullet:
            '• I redesigned the UI to optimize the web to the maximum, increasing conversion by eliminating five steps in the purchasing process and converting them into only 2. With this, we retained more customers, and after two months of continuous Integrations, the benefit increased by 70%.',
        },
      ],
    },
  ],
  skillsDescription: '',
  skills: [
    {
      skillname: 'HTML5',
    },
    {
      skillname: 'CSS',
    },
    {
      skillname: 'JavaScript ES6+',
    },
    {
      skillname: 'React',
    },
    {
      skillname: 'Nodejs',
    },
    {
      skillname: 'TypeScript',
    },
    {
      skillname: 'Express',
    },
    {
      skillname: 'MongoDB',
    },
    {
      skillname: 'GraphQL',
    },
    {
      skillname: 'Sass',
    },
    {
      skillname: 'Material-UI',
    },
    {
      skillname: 'Shakra-UI',
    },
    {
      skillname: 'Bulma',
    },
    {
      skillname: 'AWS (Amazon Web Services)',
    },
    {
      skillname: 'Firebase',
    },
    {
      skillname: 'Heroku',
    },
    {
      skillname: 'Vercel',
    },
    {
      skillname: 'GCP (Google Cloud Platform)',
    },
    {
      skillname: 'CI/CD with Github Actions',
    },
    {
      skillname: 'Oauth2',
    },
    {
      skillname: 'Product Design',
    },
    {
      skillname: 'Product development',
    },
    {
      skillname: 'Agile methodologies',
    },
  ],
  portfolio: [
    {
      name: 'Wellbe',
      description: 'Platform to conect patients with doctors',
      imgurl: 'images/portfolio/wellbewebrespons.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Wellbe',
      description: 'Platform to conect patients with doctors',
      imgurl: 'images/portfolio/wellbeweb.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Platzi-Badges',
      description: 'Badge Management System',
      imgurl: 'images/portfolio/platzi-badges.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Fitness App',
      description: 'Webapp with Reactjs',
      imgurl: 'images/portfolio/fitness-app.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Platzi-video',
      description: 'Reactjs Streaming video app',
      imgurl: 'images/portfolio/platzi-video.jpg',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Platzi-video',
      description: 'Reactjs Streaming video app',
      imgurl: 'images/portfolio/platzi-video-two.jpg',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Express Books',
      description: 'Nodejs Books app',
      imgurl: 'images/portfolio/expressbooks.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
    {
      name: 'Ricky & Morty app',
      description: 'Reactjs app about ricky and morty characters Api',
      imgurl: 'images/portfolio/rickyandmorty.png',
      modal: 'images/portfolio/modals/m-wellbewebrespons.png',
    },
  ],
  testimonials: [
    {
      description:
        'Jerson is a focused person with a vision aligned for the future, with a spirit of serving and undertaking, professionalism and great ethics in the projects he leads, excellent trouble solver and possessor of distinguished ability to work in environments under pressure, capacity to making decisions at the right time, honest and reliable to develop just any project whose vision is to have a positive impact on society.',
      name: 'Axel Leal',
    },
    {
      description:
        'Jerson is one of the most dedicated, consistent entrepreneurs and developers I`ve encountered. he develops deep, lasting relationships with his co-founders and co-workers,  Jerson is someone you can give responsibility to the outside of his experience and he will step up to the task.',
      name: 'Fabian Martinez',
    },
  ],
};

export default resumeData;
